import React, { useRef, useEffect, useState } from 'react';
import icon from '../../images/interior_logo.png';
import ic_one from '../../images/ic_one.png';
import ic_two from '../../images/ic_two.png';
import ic_three from '../../images/ic_three.png';

import img_demo from '../../images/image.png';

import { ImageLogo, Image, ContainerFlex, Text, Container, ActionText } from '../StyledComponents';
import LandingForm from './LandingForm';
import Footer from './Footer';

const OptionOneView = () => {

    const [height, setHeight] = useState(0)

    const fomrRef = useRef(null)
    const videoRef = useRef(null)

    const scrollToRef = (ref) => ref.current.scrollIntoView({ behavior: 'smooth' })

    const _handleVideo = () => {
        window.open("https://www.youtube.com/watch?v=GNAO_oG6tiE&feature=youtu.be", "_blank")
    }

    useEffect(() => {
        const idealHeight = (videoRef.current.offsetWidth * 315) / 560
        console.log(idealHeight)
        setHeight(idealHeight)
    }, [])

    return (
        <Container>
            <ContainerFlex width="12" align="stretch" justify="center" minheight="100vh">


                <ContainerFlex width="12" sm="6" lg="6" xl="4" color="color-primary" align="center" justify="center" minheight="100vh">

                    <ContainerFlex width="10" sm="9" lg="7" direction="column" justify="center" pv="40">
                        <ImageLogo mb="50px" src={icon} />

                        <Container>
                            <Text size="35" color="color-white" children="Interiors Living Lab" />
                            <Text ml="6" mb="16" size="25" color="color-white" children='es la primera herramienta de innovación que permite "medir" cómo el interiorismo modifica el comportamiento, la emoción y la percepción de las personas en un espacio determinado.' />
                        </Container>

                        <Text size="25" color="color-white" children="El apoyo idóneo para sacar el máximo rendimiento durante el diseño de espacios en oficinas, centros de salud, escuelas, retail y hoteles." />
                        <Text mt="32" mb="16" size="32" color="color-white" children="Quieres saber más?" />

                        <Container>
                            <ActionText size="25" color="color-white" onclick={() => scrollToRef(fomrRef)} children="Contacta aquí" />
                            <Text ml="6" size="25" color="color-white" children="con el Director de Interiors Living Lab" />
                        </Container>

                        <ContainerFlex width="12" mt="32" justify="flex-end" align="center">
                            <Image src={ic_one} width="48" />
                        </ContainerFlex>
                        <ContainerFlex width="12" mt="8" mb="32" justify="flex-end" align="center">
                            <Image src={ic_three} width="48" />
                            <Image ml="8px" src={ic_two} width="48" />
                        </ContainerFlex>
                    </ContainerFlex>

                </ContainerFlex>

                <Container width="0" sm="6" lg="6" xl="8" position="relative" minheight="100vh" color="color-primary">
                    <Container position="absolute" top="0" left="0" zindex="1" width="12" minheight="100%" image={img_demo} imageSize="cover" imagePosition="bottom right" />
                    {
                        /*
                    <Container position="absolute" top="0" left="0" zindex="2" width="12" minheight="100%">
                        <ImageLogo mt="25px" mr="16px" mb="16px" ml="25px" src={icon} />
                    </Container>
                        */
                    }
                </Container>


            </ContainerFlex>

            <ContainerFlex width="12" align="center" justify="center" pv="64" color="color-yellow">
                <ContainerFlex direction="column" width="12" lg="5" ph="32">
                    <Text mb="16" size="45" color="color-red" children="Conoce nuestras Concept Rooms" />
                    <Text size="25" children="Cuatro espacios a modo de laboratorio de ideas sobre el futuro -cercano y lejano- de los hoteles. En ellos, cuatro estudios creativos de campos como el interiorismo, la arquitectura o el diseño, realizan propuestas innovadoras que exploran cómo las disciplinas creativas pueden ayudar a generar hoteles, no solo desde el punto de vista de la decoración y la distribución, sino también del modelo de negocio. Una visión que trasciende la función que tradicionalmente asume el interiorismo para abordar una nueva manera de entender la profesión." />

                    <ContainerFlex onclick={_handleVideo} direction="column" mt="20">
                        <Text size="25" children="Conoce en más detalle el trabajo realizado por los estudios de interiorismo participantes en las Concept Rooms de interihotel 2019." cursor="pointer" />
                    </ContainerFlex>
                </ContainerFlex>
                <Container justify="center" width="12" lg="7" px="32">
                    <div ref={videoRef}>
                        <iframe title="Interiors Living Lab" width="100%" height={`${height}px`}
                            src="https://www.youtube.com/embed/YJNQkP4sqw0"
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                    </div>
                </Container>
            </ContainerFlex>

            <ContainerFlex myref={fomrRef} width="12" align="center" justify="center" minheight="100vh">
                <ContainerFlex justify="center" width="10" lg="6" >
                    <LandingForm />
                </ContainerFlex>
            </ContainerFlex>

            <Footer />

        </Container>

    );
};

export default OptionOneView;