import React from 'react';
import styled from 'styled-components';

/* UTIL */
const widthResponsive = (attr) => {
    const { width, xs, sm, lg, xl } = attr;
    const mwidth = `${width ? `col-${width}` : ``}${xs ? ` col-xs-${xs}` : ``}${sm ? ` col-sm-${sm}` : ``}${lg ? ` col-lg-${lg}` : ``}${xl ? ` col-xl-${xl}` : ``}`;
    return mwidth;
}

const marginAndPadding = (props) => {
    const { px = 0, pt, pr, pb, pl, ph, pv } = props;
    const { mx = 0, mt, mr, mb, ml, mh, mv } = props;
    const padding = `${pt ? pt : pv ? pv : px}px ${pr ? pr : ph ? ph : px}px ${pb ? pb : pv ? pv : px}px ${pl ? pl : ph ? ph : px}px`;
    const margin = `${mt ? mt : mv ? mv : mx}px ${mr ? mr : mh ? mh : mx}px ${mb ? mb : mv ? mv : mx}px ${ml ? ml : mh ? mh : mx}px`;

    return `
        padding: ${padding};
        margin: ${margin};
    `;
}

/* COMPONENT CONTAINER */
const FmuiContainer = styled.div`
    ${props => {
        //Set default values
        const { position = 'initial', top, rigth, bottom, left } = props.attr;
        const { height, minwidth, maxwidth, minheight, maxheight, overflowY = "initial" } = props.attr;
        const { color, image, imageSize,imagePosition, opacity = 1, zindex = 0 } = props.attr;

        return `
        position: ${position};
        display: block;
        ${top ? `top: ${top};` : ``}
        ${rigth ? `rigth: ${rigth};` : ``}
        ${bottom ? `bottom: ${bottom};` : ``}
        ${left ? `left: ${left};` : ``}

        ${minwidth ? `min-width: ${minwidth};` : ``}
        ${minheight ? `min-height: ${minheight};` : ``}
        ${maxwidth ? `max-width: ${maxwidth};` : ``}
        ${maxheight ? `max-height: ${maxheight};` : ``}
        ${height ? `height: ${height};` : ``}


        ${marginAndPadding(props.attr)}
        ${color ? `background-color: ${color && color.includes('#') ? color : `var(--${color})`};` : ``}
        ${image ? `background-image: url(${image});` : ``}
        ${imageSize ? `background-size: ${imageSize};` : ``}
        ${imagePosition? `background-position: ${imagePosition};` : ``}

        box-sizing: border-box;
        overflow-y: ${overflowY};
        opacity: ${opacity};
        z-index: ${zindex};
    `;
    }}
`;

export const Container = (attr) => {
    const { extras, children, myref } = attr;
    const mwidth = widthResponsive(attr);
    return (
        <FmuiContainer
            ref={myref}
            attr={attr}
            className={`${mwidth}${extras ? ` ${extras}` : ""}`}
            children={children} />
    );
}

/* COMPONENT CONTAINER-FLEX */
const FmuiContainerFlex = styled(FmuiContainer)`
    ${props => {
        //Set defaults values
        const { multiline = true } = props.attr;
        const {
            direction = "row",
            justify = "flex-start",
            align = "flex-start",
            alignContent = "auto" } = props.attr;

        return `
        display: flex;
        flex-wrap: ${multiline ? "wrap" : "nowrap"};
        flex-direction: ${direction};
        justify-content: ${justify};
        align-items: ${align};
        align-content: ${alignContent}
    `;
    }}
`;

export const ContainerFlex = (attr) => {
    const { extras, children, myref, onclick } = attr;
    const mwidth = widthResponsive(attr);
    return (
        <FmuiContainerFlex
            onClick={onclick}
            ref={myref}
            attr={attr}
            className={`${mwidth}${extras ? ` ${extras}` : ""}`}
            children={children} />
    );
}

/* COMPONENT TEXT */
const FmuiLabel = styled.label`
    ${props => {
        const {
            size = 16,
            weight = "normal",
            color,
            cursor = "text",
            align = "start"
        } = props.attr;

        return `
            font-size: ${size}px;
            font-weight: ${weight};
            ${color ? `color: var(--${color});` : ''}
            cursor: ${cursor};
            text-align: ${align};
            ${marginAndPadding(props.attr)}
        `;
    }}
`;

export const Text = (attr) => {
    return (
        <FmuiLabel attr={attr}>
            {attr.children}
        </FmuiLabel>
    );
};

const FmuiActionText = styled(FmuiLabel)`
    text-decoration: underline;
    cursor: pointer;
`;

export const ActionText = (attr) => {
    const { children, onclick } = attr;
    return (
        <FmuiActionText
            attr={attr}
            onClick={onclick}
            children={children} />
    );
}

export const SectionThree = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height:100vh;

    @media (max-width: 1640px) {
        width: 35%;
    }

    @media (max-width: 1280px) {
        width: 50%;
    }

    @media (max-width: 768px) {
        width: 90%;
    }
`;

export const Dots = styled.div`
    width: 8px;
    height: 8px;
    background-color: ${props => props.color};
    margin: 8px 16px;
    border-radius: 12px;
    box-shadow: 0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15);
`;

export const BtnAccent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    padding: 16px;
    margin: 16px 0;
    text-align: center;
    background-color: #e59b50;
    cursor:pointer;
    box-shadow: 0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15);
`;

export const Image = styled.img`
    margin-top: ${props => props.mt};
    margin-left: ${props => props.ml};
    margin-right: ${props => props.mr};
    margin-bottom: ${props => props.mb};
`;

export const ImageLogo = styled(Image)`
    height: 148px;
    @media (max-width: 411px) {
        height: 118px;
    }

    @media (max-width: 320px) {
        height: 88px;
    }
`;

export const Input = styled.input`
    outline: 0px;   
    border-radius: 4px;
    border: 1px solid #31586d;
    padding: 16px;
    flex-grow: 1;
`;

export const TextArea = styled.textarea`
    resize: none;
    outline: 0px;
    border-radius: 4px;
    border: 1px solid #31586d;
    padding: 16px;
    flex-grow: 1;
`;

export const AlertForm = styled.div`
    color: white;
    background-color: ${props => props.color};
    padding: 8px 16px;
    flex-grow: 1
    border-radius: 3px;
    box-shadow: 0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15);
    text-align: center;
`;