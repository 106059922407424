import React from 'react';
import OptionOneView from './components/optionOne/OptionOneView';

function App() {
  return (
    <OptionOneView />
  );
}

export default App;
