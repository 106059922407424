import React from 'react';
import { ContainerFlex, Container, Text } from '../StyledComponents';
import ic_diputacio from '../../images/footer_diputacio.jpg'
import ic_footer from '../../images/footer.png'

const Footer = () => {
    return (
        <ContainerFlex px="16" align="center">
            <Container width="12" lg="6" px="32">
                <Container width="12" children={<Text children={`Interiors Living Lab de AMBIT es una actividad de innovación abierta desarrollada dentro de la operación "Laboratori d'Hàbitat" del proyecto “PECT TurisTIC en família”, coordinado por la Diputación de Tarragona y cofinanciado por el Fondo Europeo de Desarrollo Regional (FEDER) de la Unión Europea, en el marco del Programa operativo FEDER de Cataluña 2014-2020. Objetivo de inversión en crecimiento y ocupación.`} />} />
                <Container width="12" pv="32" children={<Text weight="bold" children="Con la ayuda de" />} />
                <img alt="diputacio" height="100" src={ic_diputacio} />
            </Container>
            <Container width="12" lg="6" px="32" children={<img alt="generalitat" width="100%" src={ic_footer} />} />
        </ContainerFlex>
    );
};

export default Footer;