import React, { Fragment, useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { ContainerFlex, Text, BtnAccent, Dots, Input, TextArea, AlertForm, ActionText } from '../StyledComponents';

const LandingForm = () => {

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [message, setMessage] = useState("")
    const [reCaptcha, setReCaptcha] = useState(false)

    const [alert, setAlert] = useState({
        "show": false,
        "color": "red",
        "message": "Todos los campos son obligatorios"
    })


    const onChange = (value) => {
        console.log("Captcha value:", value);
        setReCaptcha(true)
    }

    const _sendMessage = async (message, success, error) => {
        const request = await fetch("http://livinglab.ambitcluster.org/mail/send.php", {
            method: "POST",
            body: JSON.stringify(message),
            headers: {
                'Content-Type': 'application/json',
            }
        })

        if (request.ok) {
            try {
                success()
            } catch (error) {
                error()
            }
        } else {
            error()
        }
    }

    const _submitForm = (e) => {
        e.preventDefault();
        // Check required fields
        if (name !== "" && email !== "" && phone !== "" && message !== "") {
            if (reCaptcha) {
                // Create form object
                let form = {
                    "name": name,
                    "email": email,
                    "phone": phone,
                    "message": message
                }
                console.log(form)
                // Send form to server
                _sendMessage(form,
                    () => {
                        setAlert({ "show": true, "color": "green", "message": "Mensaje enviado correctamente" })
                        // Reset fields
                        setName("")
                        setEmail("")
                        setPhone("")
                        setMessage("")
                    }, () => {
                        setAlert({ "show": true, "color": "red", "message": "Error al enviar el formulario" })
                    });
            } else {
                setAlert({ "show": true, "color": "red", "message": "Verifique el reCAPTCHA" })
            }
        } else {
            setAlert({ "show": true, "color": "red", "message": "Todos los campos son obligatorios" })
        }
    }

    const alertComponent = alert.show ?
        <ContainerFlex width="12">
            <AlertForm color={alert.color} children={alert.message} />
        </ContainerFlex>
        : null

    return (
        <Fragment>

            <ContainerFlex mt="32" width="12" justify="center">
                <Text size="45" align="center" color="color-primary" children="Contacto Interiors Living Lab" />
            </ContainerFlex>
            <ContainerFlex width="12" justify="center" mv="16">
                <Dots color="#31586d" />
                <Dots color="#e59b50" />
                <Dots color="#993538" />
            </ContainerFlex>

            <ContainerFlex width="12" mv="16">
                <Input
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    placeholder="Nombre"
                    type="text" />
            </ContainerFlex>
            <ContainerFlex width="12" mv="16">
                <Input
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    placeholder="Email"
                    type="text" />
            </ContainerFlex>
            <ContainerFlex width="12" mv="16">
                <Input
                    onChange={(e) => setPhone(e.target.value)}
                    value={phone}
                    placeholder="Teléfono"
                    type="text" />
            </ContainerFlex>
            <ContainerFlex width="12" mv="16">
                <TextArea
                    onChange={(e) => setMessage(e.target.value)}
                    rows="10"
                    placeholder="Mensaje"
                    value={message} />
            </ContainerFlex>

            {alertComponent}

            <ContainerFlex justify="center" width="12" mv="8">
                <ActionText color="color-white" onclick={() => window.open("https://cenfim.org/es/politica-de-privacidad", "_blank")} align="center" children="Al enviar acepta nuestra política de privacidad y condiciones de uso" />
            </ContainerFlex>

            <ContainerFlex width="12" align="center" justify="space-around" mv="16">
                <ReCAPTCHA
                    sitekey="6LeOv8IUAAAAAF-KotaQoMW5tkONKljU-9HIIZB3"
                    onChange={onChange}
                />
                <BtnAccent
                    onClick={_submitForm}
                    children="Enviar consulta" />
            </ContainerFlex>
        </Fragment>
    );
};

export default LandingForm;